import React from 'react';
import { useTranslation } from 'react-i18next';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import { GET_API_VERSION } from 'operations/queries/getApiVersion';

interface Props { }

const Copyright: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const version = '1.28.31';
  const { data } = useQuery(GET_API_VERSION, {});

  return (
    <div className={classes.root}>
      <Typography variant="caption">© {year} {t('copyright.text')} v{version}{data?.getApiVersion}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    color: theme.palette.text.hint,
    textAlign: 'center',
  },
}));

export default Copyright;
